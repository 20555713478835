import { FormProvider, useForm } from "react-hook-form";
import { useResponsive } from "../../hooks/customUseResponsive";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Grid,
  // InputAdornment,
  MenuItem,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./styles.module.css";
import RHFTextField from "../RFH/RFHtextfield";
import RHFRadioGroup from "../RFH/RFHradioGroup";
import { RHFFileUploader } from "../RFH/RFHfileUploader";
import { RFHMultiCheckbox } from "../RFH/RFHMultiCheckbox";
import { LoadingButton } from "@mui/lab";
import employeeDetailsSchema from "../../schemas/employeeDetails";
import DatePickerComponent from "../DatePickerComp/DatePickerComp";
import dayjs from "dayjs";
import { RHFSelect } from "../RFH/RFHselect";
import { useEffect } from "react";
import { PersonalInfoQuery } from "../../utils/reactQuery/profile/PersonalInfo";
import { useMutation } from "@tanstack/react-query";
import onBoardEmployee from "../../services/manager/onBoard/apiOnBoardEmployee";
import toast from "react-hot-toast";
import { formatDate } from "../../utils/helpers";
import ManagersListQuery from "../../utils/reactQuery/ManagersList";
import Api from "../../services/axios";

const genderOptions = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
];

const statusOptions = [
  { value: "Active", label: "Active" },
  { value: "Inactive", label: "Inactive" },
];

const permissionsOptions = [
  { value: "AddAttendance", label: "Add Attendance" },
  { value: "ApproveAttendance", label: "Approve Attendance" },
];

const managerOptions = [
  {
    value: "1",
    label: "Apurva Gadwanshi",
  },
  {
    value: "2",
    label: "Paresh Chouhan",
  },
];

export default function OnBoard({ openProp, handleClose, currentEmployeeId }) {
  const isMobile = useResponsive("down", "sm");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? 350 : 1100,
    bgcolor: "#F8F9FA",
    boxShadow: 24,
    borderRadius: "10px",
    maxHeight: "90vh",
    overflowY: "auto",
  };

  const overrideTextFieldStyle = {
    "& .MuiOutlinedInput-input": {
      padding: isMobile ? "6px" : "10px",
      color: "#6C757D",
      backgroundColor: "#fff",
      border: "1.5px solid #CED4DA",
      borderRadius: "4px",
      fontSize: isMobile ? "12px" : "14px",
      fontWeight: 400,
    },
  };

  const { employee, documents } = PersonalInfoQuery(currentEmployeeId);
  console.log("OnBoard employee data", employee);
  console.log("OnBoard employee documents", documents);

  const a = ManagersListQuery();

  const { mutate, isPending } = useMutation({
    mutationFn: onBoardEmployee,
    onSuccess: (data) => {
      console.log("success repsonse", data);
      toast.success(data.data.message);
      methods.reset();
      handleClose();
    },
    onError: (data) => {
      console.log("error onBoarding employee", data.response.data);
    },
  });

  const defaultValues = {
    name: "",
    graduationYear: "",
    education: "",
    experience: "",
    phoneNumber: "",
    employeeId: null,
    designation: "",
    technology: "",
    managerId: "",
    joiningDate: null,
    relievingDate: null,
    documentLink: "",
    gender: "",
    dob: null,
    currentAddress: "",
    currentCity: "",
    currentState: "",
    currentZip: "",
    permanentAddress: "",
    permanentCity: "",
    permanentState: "",
    permanentZip: "",
    adharCard: "",
    panCard: "",
    driverLicense: "",
    pfAccount: "",
    esicNo: "",
    ldap: "",
    fatherName: "",
    motherName: "",
    documents: null,
    status: "",
    permission: [],
  };

  const methods = useForm({
    resolver: yupResolver(employeeDetailsSchema),
    defaultValues,
  });

  const onSubmit = (formData) => {
    console.log("formData === ", formData);
    // const transformedData = {
    //   ...formData,
    //   permission: JSON.stringify(formData.permission),
    //   dob: formatDate(formData.dob),
    //   joiningDate: formatDate(formData.joiningDate),
    //   relievingDate: formData.relievingDate
    //     ? formatDate(formData.relievingDate)
    //     : null,
    // };
    // // mutate(transformedData);
    // const fd = new FormData();
    // fd.append()
    const formDataToSubmit = new FormData();

    formDataToSubmit.append("name", formData.name || "");
    formDataToSubmit.append("graduationYear", formData.graduationYear || "");
    formDataToSubmit.append("education", formData.education || "");
    formDataToSubmit.append("experience", formData.experience || "");
    formDataToSubmit.append("phoneNumber", formData.phoneNumber || "");
    formDataToSubmit.append("employeeId", formData.employeeId || null);
    formDataToSubmit.append("designation", formData.designation || "");
    formDataToSubmit.append("technology", formData.technology || "");
    formDataToSubmit.append("managerId", formData.managerId || "");
    formDataToSubmit.append(
      "joiningDate",
      formatDate(formData.joiningDate) || null
    );
    formDataToSubmit.append(
      "relievingDate",
      formatDate(formData.joiningDate) || null
    );
    formDataToSubmit.append("documentLink", formData.documentLink || "");
    formDataToSubmit.append("gender", formData.gender || "");
    formDataToSubmit.append("dob", formatDate(formData.dob) || null);
    formDataToSubmit.append("currentAddress", formData.currentAddress || "");
    formDataToSubmit.append("currentCity", formData.currentCity || "");
    formDataToSubmit.append("currentState", formData.currentState || "");
    formDataToSubmit.append("currentZip", formData.currentZip || "");
    formDataToSubmit.append(
      "permanentAddress",
      formData.permanentAddress || ""
    );
    formDataToSubmit.append("permanentCity", formData.permanentCity || "");
    formDataToSubmit.append("permanentState", formData.permanentState || "");
    formDataToSubmit.append("permanentZip", formData.permanentZip || "");
    formDataToSubmit.append("adharCard", formData.adharCard || "");
    formDataToSubmit.append("panCard", formData.panCard || "");
    formDataToSubmit.append("driverLicense", formData.driverLicense || "");
    formDataToSubmit.append("pfAccount", formData.pfAccount || "");
    formDataToSubmit.append("esicNo", formData.esicNo || "");
    formDataToSubmit.append("ldap", formData.ldap || "");
    formDataToSubmit.append("fatherName", formData.fatherName || "");
    formDataToSubmit.append("motherName", formData.motherName || "");
    formDataToSubmit.append("documents", formData.documents);
    formDataToSubmit.append("status", formData.status || "");

    // Append permissions array if it exists and has values
    if (formData.permission && formData.permission.length > 0) {
      formDataToSubmit.append("permission", JSON.stringify(formData.permission));
    } else {
      formDataToSubmit.append("permission", ""); // Append empty if no permissions
    }

    // Api.post("/on_boarding", formDataToSubmit, {
    //   // headers: {
    //   //   "Content-Type": "application/x-www-form-urlencoded",
    //   // },
    // })
    //   .then((data) => console.log("api data", data.data))
    //   .catch((err) => console.log("error", err));
    mutate(formDataToSubmit)
  };

  console.log("errors === ", methods.formState.errors);

  useEffect(() => {
    if (employee || documents) {
      methods.reset({
        name: employee.name,
        graduationYear: employee.graduationYear,
        education: employee.education,
        experienceYear: employee.experienceYear,
        experienceMonth: employee.experienceMonth,
        phoneNumber: employee.phoneNumber,
        employeeId: employee.employeeId,
        designation: employee.designation,
        technology: employee.technology,
        managerId: employee.managerId,
        joiningDate: employee.joiningDate,
        relievingDate: employee.relievingDate,
        documentLink: employee.documentLink,
        gender: employee.gender,
        dob: employee.dob,
        currentAddress: employee.currentAddress,
        currentCity: employee.currentCity,
        currentState: employee.currentState,
        currentZip: employee.currentState,
        permanentAddress: employee.permanentAddress,
        permanentCity: employee.permanentCity,
        permanentState: employee.permanentState,
        permanentZip: employee.permanentZip,
        adharCard: employee.adharCard,
        panCard: employee.panCard,
        driverLicense: employee.driverLicense,
        pfAccount: employee.pfAccount,
        esicNo: employee.esicNo,
        ldap: "",
        fatherName: employee.fatherName,
        motherName: employee.motherName,
        documents: documents,
        status: employee.employeeStatus,
        permission: employee.permission || [],
      });
    }
  }, [documents, employee, methods]);

  return (
    <Modal open={openProp} onClose={handleClose}>
      <Box sx={{ ...style }}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="row" className={styles.header}>
              <Typography className={styles.heading}>
                On Board Employee
              </Typography>
              <Box onClick={() => handleClose()}>
                <CloseIcon className={styles.icon} />
              </Box>
            </Stack>
            <Grid container paddingX={2} paddingY={2} columnSpacing={2}>
              <Grid item xs={12} sm={4}>
                <Grid container rowSpacing={2}>
                  <Grid item width="100%">
                    <Typography className={styles.sectionHeader}>
                      General Info
                    </Typography>
                    <Typography className={styles.label}>
                      Employee Name
                    </Typography>
                    <RHFTextField name="name" sx={overrideTextFieldStyle} />
                  </Grid>
                  <Grid item width="100%">
                    <Typography className={styles.label}>
                      Graduation Year
                    </Typography>
                    <RHFTextField
                      name="graduationYear"
                      sx={overrideTextFieldStyle}
                    />
                  </Grid>
                  <Grid item width="100%">
                    <Typography className={styles.label}>Education</Typography>
                    <RHFTextField
                      name="education"
                      sx={overrideTextFieldStyle}
                    />
                  </Grid>
                  <Grid item width="100%">
                    <Typography className={styles.label}>Experience</Typography>
                    <Box display="flex" justifyContent="space-between" gap={2}>
                      <RHFTextField
                        name="experienceYear"
                        placeholder="Year"
                        // InputProps={{
                        //   endAdornment: (
                        //     <InputAdornment position="end">Year</InputAdornment>
                        //   ),
                        // }}
                        sx={overrideTextFieldStyle}
                      />
                      <RHFTextField
                        name="experienceMonth"
                        placeholder="Month"
                        // InputProps={{
                        //   endAdornment: (
                        //     <InputAdornment position="end">
                        //       Month
                        //     </InputAdornment>
                        //   ),
                        // }}
                        sx={overrideTextFieldStyle}
                      />
                    </Box>
                  </Grid>
                  <Grid item width="100%">
                    <Typography className={styles.label}>
                      Mobile Number
                    </Typography>
                    <RHFTextField
                      name="phoneNumber"
                      sx={overrideTextFieldStyle}
                    />
                  </Grid>
                </Grid>

                <Grid container rowSpacing={2}>
                  <Grid item width="100%">
                    <Typography className={styles.sectionHeader}>
                      Job Details
                    </Typography>
                    <Typography className={styles.label}>
                      Employee ID
                    </Typography>
                    <RHFTextField
                      name="employeeId"
                      sx={overrideTextFieldStyle}
                    />
                  </Grid>
                  <Grid item width="100%">
                    <Typography className={styles.label}>
                      Designation
                    </Typography>
                    <RHFTextField
                      name="designation"
                      sx={overrideTextFieldStyle}
                    />
                  </Grid>
                  <Grid item width="100%">
                    <Typography className={styles.label}>Technology</Typography>
                    <RHFTextField
                      name="technology"
                      sx={overrideTextFieldStyle}
                    />
                  </Grid>
                  <Grid item width="100%">
                    <Typography className={styles.label}>Manager</Typography>
                    <RHFSelect name="managerId">
                      {managerOptions.map((ele, idx) => {
                        return (
                          <MenuItem value={ele.value} key={idx}>
                            {ele.label}
                          </MenuItem>
                        );
                      })}
                    </RHFSelect>
                  </Grid>
                  <Grid item width="100%">
                    <Typography className={styles.label}>
                      Date of Joining
                    </Typography>
                    <DatePickerComponent name="joiningDate" isFullWidth />
                  </Grid>
                  <Grid item width="100%">
                    <Typography className={styles.label}>
                      Relieving Date
                    </Typography>
                    <DatePickerComponent name="relievingDate" isFullWidth />
                  </Grid>
                  <Grid item width="100%">
                    <Typography className={styles.label}>
                      Employee Documents Link
                    </Typography>
                    <RHFTextField
                      name="documentLink"
                      sx={overrideTextFieldStyle}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Grid container rowSpacing={2}>
                  <Grid item width="100%">
                    <Typography className={styles.sectionHeader}>
                      Additional Info
                    </Typography>
                    <Typography className={styles.label}>Gender</Typography>
                    <RHFSelect name="gender">
                      {genderOptions.map((ele, idx) => {
                        return (
                          <MenuItem value={ele.value} key={idx}>
                            {ele.label}
                          </MenuItem>
                        );
                      })}
                    </RHFSelect>
                  </Grid>
                  <Grid item width="100%">
                    <Typography className={styles.label}>
                      Date of Birth
                    </Typography>
                    <DatePickerComponent name="dob" isFullWidth />
                  </Grid>
                </Grid>

                <Grid container rowSpacing={2}>
                  <Grid item width="100%">
                    <Typography className={styles.sectionHeader}>
                      Current Address
                    </Typography>
                    <Typography className={styles.label}>Address</Typography>
                    <RHFTextField
                      name="currentAddress"
                      sx={overrideTextFieldStyle}
                    />
                  </Grid>
                  <Grid item width="100%">
                    <Typography className={styles.label}>City</Typography>
                    <RHFTextField
                      name="currentCity"
                      sx={overrideTextFieldStyle}
                    />
                  </Grid>
                  <Grid item width="100%">
                    <Typography className={styles.label}>State</Typography>
                    <RHFTextField
                      name="currentState"
                      sx={overrideTextFieldStyle}
                    />
                  </Grid>
                  <Grid item width="100%">
                    <Typography className={styles.label}>Zip</Typography>
                    <RHFTextField
                      name="currentZip"
                      sx={overrideTextFieldStyle}
                    />
                  </Grid>
                </Grid>

                <Grid container rowSpacing={2}>
                  <Grid item width="100%">
                    <Typography className={styles.sectionHeader}>
                      Permanent Address
                    </Typography>
                    <Typography className={styles.label}>Address</Typography>
                    <RHFTextField
                      name="permanentAddress"
                      sx={overrideTextFieldStyle}
                    />
                  </Grid>
                  <Grid item width="100%">
                    <Typography className={styles.label}>City</Typography>
                    <RHFTextField
                      name="permanentCity"
                      sx={overrideTextFieldStyle}
                    />
                  </Grid>
                  <Grid item width="100%">
                    <Typography className={styles.label}>State</Typography>
                    <RHFTextField
                      name="permanentState"
                      sx={overrideTextFieldStyle}
                    />
                  </Grid>
                  <Grid item width="100%">
                    <Typography className={styles.label}>Zip</Typography>
                    <RHFTextField
                      name="permanentZip"
                      sx={overrideTextFieldStyle}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Grid container rowSpacing={2}>
                  <Grid item width="100%">
                    <Typography className={styles.sectionHeader}>
                      Identification & Account Information
                    </Typography>
                    <Typography className={styles.label}>Aadhar #</Typography>
                    <RHFTextField
                      name="adharCard"
                      sx={overrideTextFieldStyle}
                    />
                  </Grid>
                  <Grid item width="100%">
                    <Typography className={styles.label}>PAN #</Typography>
                    <RHFTextField name="panCard" sx={overrideTextFieldStyle} />
                  </Grid>
                  <Grid item width="100%">
                    <Typography className={styles.label}>
                      Driving License #
                    </Typography>
                    <RHFTextField
                      name="driverLicense"
                      sx={overrideTextFieldStyle}
                    />
                  </Grid>
                  <Grid item width="100%">
                    <Typography className={styles.label}>
                      PF Account #
                    </Typography>
                    <RHFTextField
                      name="pfAccount"
                      sx={overrideTextFieldStyle}
                    />
                  </Grid>
                  <Grid item width="100%">
                    <Typography className={styles.label}>
                      ESIC Number #
                    </Typography>
                    <RHFTextField name="esicNo" sx={overrideTextFieldStyle} />
                  </Grid>
                  <Grid item width="100%">
                    <Typography className={styles.label}>
                      LDAP Account
                    </Typography>
                    <RHFTextField name="ldap" sx={overrideTextFieldStyle} />
                  </Grid>
                </Grid>

                <Grid container rowSpacing={2}>
                  <Grid item width="100%">
                    <Typography className={styles.sectionHeader}>
                      Other Details
                    </Typography>
                    <Typography className={styles.label}>
                      Father's Name
                    </Typography>
                    <RHFTextField
                      name="fatherName"
                      sx={overrideTextFieldStyle}
                    />
                  </Grid>
                  <Grid item width="100%">
                    <Typography className={styles.label}>
                      Mother's Name
                    </Typography>
                    <RHFTextField
                      name="motherName"
                      sx={overrideTextFieldStyle}
                    />
                  </Grid>
                  <Grid item width="100%">
                    <Typography className={styles.label}>
                      Profile Photo
                    </Typography>
                    <RHFFileUploader name="documents" />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Grid container rowSpacing={2}>
                  <Grid item width="100%">
                    <Typography className={styles.sectionHeader}>
                      Status
                    </Typography>
                    <RHFRadioGroup
                      name="status"
                      options={statusOptions}
                      row={true}
                    />
                  </Grid>
                  <Grid item width="100%">
                    <Typography className={styles.sectionHeader}>
                      Permissions
                    </Typography>

                    <RFHMultiCheckbox
                      name="permission"
                      options={permissionsOptions}
                      row={true}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Stack className={styles.btnCont}>
              <Box display="flex" justifyContent="flex-end">
                <LoadingButton
                  type="submit"
                  variant="contained"
                  className={styles.btn}
                >
                  Submit
                </LoadingButton>
              </Box>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </Modal>
  );
}
