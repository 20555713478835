import { useState, useEffect } from "react";
import styles from "./styles.module.css";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TableHeader from "../../../components/TableHeader/TableHeader";
import LeaveStatement from "./LeaveStatement/LeaveStatement";
import LeaveLog from "./LeaveLog/LeaveLog";
import AddLeaveModal from "./AddLeaveModal/AddLeaveModal";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { EmployeeUnderManagerQuery } from "../../../utils/reactQuery/manager/EmployeeUnderManager";
import { PersonalInfoQuery } from "../../../utils/reactQuery/profile/PersonalInfo";
import useEmployeeSelection from "../../../hooks/useEmployeeSelection";
import { ShowUserData } from "../../../utils/reactQuery/ShowUserData";

export default function LeaveManagement() {
  const [value, setValue] = useState("leaveStatement");
  const [addleaveModal, setAddleaveModal] = useState(false);
  const { currentEmployee, emplist, onCurrentEmployeeChange } =
    useEmployeeSelection();

  const { user } = ShowUserData();

  // const [currentEmployee, setCurrentEmployee] = useState({
  //   employee_id: "",
  //   name: "",
  // });
  // const [emplist, setEmplist] = useState([]);
  // const { employeeList } = EmployeeUnderManagerQuery();
  // const { employee } = PersonalInfoQuery();

  // // console.log("leave management employeeList", employeeList);
  // // console.log("leave management emplist", emplist);

  // useEffect(() => {
  //   // Set the employee list to the one retrieved initially
  //   if (employeeList) {
  //     console.log("got existing employees");
  //     setEmplist(employeeList);
  //     if (employee) {
  //       console.log("update the current employee and emplist array");
  //       // Update the current employee
  //       setCurrentEmployee({ employee_id: employee.id, name: employee.name });
  //       // Update the emplist by adding the new object at the start
  //       setEmplist((prevList) => [
  //         { employee_id: 0, name: "Select All" },
  //         ...prevList,
  //       ]);
  //     }
  //   }
  // }, [employee, employeeList]);

  // const onCurrentEmployeeChange = (event) => {
  //   // console.log("employee change event", event);
  //   const selectedEmployee = emplist.filter(
  //     (emp) => emp.employee_id === event.target.value
  //   )[0].name;
  //   // console.log("selectedEmployee", selectedEmployee);
  //   setCurrentEmployee({
  //     employee_id: event.target.value,
  //     name: selectedEmployee,
  //   });
  // };

  const handleChange = (event, value) => {
    setValue(value);
  };
  console.log("LeaveManagement current employee", currentEmployee);

  return (
    <>
      <Box className={styles.mainContainer}>
        <Box className={styles.border}></Box>

        <TableHeader
          heading={"Leave Management"}
          btntxt={"Add Leave"}
          handleModal={() => setAddleaveModal(true)}
        />
        <Box className={styles.tabContainer}>
          <Box>
            <Tabs
              aria-label="basic tabs example"
              value={value}
              onChange={handleChange}
              className={styles.tabCont}
            >
              <Tab
                label="Leave Statement"
                className={styles.tabHead}
                value="leaveStatement"
              />
              <Tab
                label="Leave Logs"
                className={styles.tabHead}
                value="leaveLogs"
              />
            </Tabs>
          </Box>
          <Box>
            <Select
              name="employeeList"
              value={currentEmployee.employee_id || user?.employee_id}
              onChange={onCurrentEmployeeChange}
              autoWidth
              className={styles.dropdown}
            >
              {emplist?.map((employee) => (
                <MenuItem
                  value={employee.employee_id}
                  key={employee.employee_id}
                >
                  {employee.name || user?.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>
        <Box>
          {value === "leaveStatement" ? (
            <LeaveStatement
              currentEmployee={currentEmployee || user?.employee_id}
            />
          ) : (
            <LeaveLog currentEmployee={currentEmployee || user?.employee_id} />
          )}
        </Box>
        <AddLeaveModal
          openProp={addleaveModal}
          handleClose={() => setAddleaveModal(false)}
        />
      </Box>
    </>
  );
}
