import { useEffect, useState } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import SummaryReportPdf from "./SummaryReportPdf";

export default function SummaryReportPdfPreview() {
  const [pdfData, setPdfData] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  useEffect(() => {
    // Retrieve the data from localStorage
    const storedPdfData = localStorage.getItem("summaryReportPdfData");
    const storedFromDate = localStorage.getItem("fromDate");
    const storedToDate = localStorage.getItem("toDate");

    if (storedPdfData) {
      setPdfData(JSON.parse(storedPdfData));
      setFromDate(storedFromDate);
      setToDate(storedToDate);
    }

    localStorage.removeItem("pdfData");
    localStorage.removeItem("fromDate");
    localStorage.removeItem("toDate");
  }, []);

  console.log("pdfdata preview", pdfData);

  return (
    <PDFViewer className="pdfViewer">
      <SummaryReportPdf pdfData={pdfData} fromDate={fromDate} toDate={toDate} />
    </PDFViewer>
  );
}
