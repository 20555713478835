import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";

// Define styles
const styles = StyleSheet.create({
  frontPage: {
    top: "30%",
    left: "30%",
  },
  textBold: {
    fontSize: 28,
    fontWeight: "bold",
  },
  textMargin: {
    marginBottom: 10,
  },
  page: {
    padding: 20,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableColHeader: {
    width: "12%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#f0f0f0",
    textAlign: "center",
  },
  adjustmentTableColHeader: {
    width: "52%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#f0f0f0",
    textAlign: "center",
  },
  adjustmentTableColSubHeader: {
    width: "8.67%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#f0f0f0",
    textAlign: "center",
  },
  tableCol: {
    width: "12%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "center",
  },
  adjustmentTableCol: {
    width: "52%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "center",
  },
  adjustmentTableSubCol: {
    width: "8.67%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "center",
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 8,
    fontWeight: "bold",
  },
  tableCell: {
    margin: 5,
    fontSize: 8,
  },
  title: {
    fontSize: 12,
    marginBottom: 10,
    textAlign: "center",
  },
  employeeName: {
    marginBottom: 20,
  },
});

export default function LeaveStatementPdf({ pdfData, currentYear }) {
  // console.log("pdf data", pdfData);
  // console.log("currentYear", currentYear);
  return (
    <Document>
      <Page>
        <View style={styles.frontPage}>
          <Text style={[styles.textBold, styles.textMargin]}>
            Leave Statement
          </Text>
          <Text style={styles.textMargin}>Abbreviations</Text>
          <Text style={styles.textMargin}>CL - Casual Leave</Text>
          <Text style={styles.textMargin}>LC - Late Coming</Text>
          <Text style={styles.textMargin}>EL - Early Leaving</Text>
          <Text style={styles.textMargin}>LWP - Leave without pay</Text>
        </View>
      </Page>
      {pdfData?.map((data) => (
        <Page size="A4" style={styles.page}>
          <View style={styles.employeeName}>
            <Text>{data.name}</Text>
          </View>

          <View style={styles.table}>
            {/* Table Header */}
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>{currentYear}</Text>
                {/* Month & Year */}
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Opening Balance</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Alloted</Text>
              </View>
              <View style={styles.adjustmentTableColHeader}>
                <Text style={styles.tableCellHeader}>Adjustments</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Closing Balance</Text>
              </View>
            </View>

            {/* Table Subheader */}
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Month & Year</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>CL</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>CL</Text>
              </View>
              <View style={styles.adjustmentTableColSubHeader}>
                <Text style={styles.tableCellHeader}>CL Used</Text>
              </View>
              <View style={styles.adjustmentTableColSubHeader}>
                <Text style={styles.tableCellHeader}>LC</Text>
              </View>
              <View style={styles.adjustmentTableColSubHeader}>
                <Text style={styles.tableCellHeader}>EL</Text>
              </View>
              <View style={styles.adjustmentTableColSubHeader}>
                <Text style={styles.tableCellHeader}>LWP</Text>
              </View>
              <View style={styles.adjustmentTableColSubHeader}>
                <Text style={styles.tableCellHeader}>Encashed</Text>
              </View>
              <View style={styles.adjustmentTableColSubHeader}>
                <Text style={styles.tableCellHeader}>Comp Off</Text>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>CL</Text>
              </View>
            </View>

            {/* Table Content */}
            {data.leaveBalance.map((row) => (
              <View key={row.id} style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{row.col1}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{row.col2}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{row.col3}</Text>
                </View>
                <View style={styles.adjustmentTableSubCol}>
                  <Text style={styles.tableCell}>{row.col4}</Text>
                </View>
                <View style={styles.adjustmentTableSubCol}>
                  <Text style={styles.tableCell}>{row.col5}</Text>
                </View>
                <View style={styles.adjustmentTableSubCol}>
                  <Text style={styles.tableCell}>{row.col6}</Text>
                </View>
                <View style={styles.adjustmentTableSubCol}>
                  <Text style={styles.tableCell}>{row.col7}</Text>
                </View>
                <View style={styles.adjustmentTableSubCol}>
                  <Text style={styles.tableCell}>{row.col8}</Text>
                </View>
                <View style={styles.adjustmentTableSubCol}>
                  <Text style={styles.tableCell}>{row.col9}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{row.col10}</Text>
                </View>
              </View>
            ))}
          </View>
        </Page>
      ))}
    </Document>
  );
}
