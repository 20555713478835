import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./styles.module.css";
import PersonalInfo from "./PersonalInfo/PersonalInfo";
import ContactInfo from "./ContactInfo/ContactInfo";
import { useResponsive } from "../../hooks/customUseResponsive";
import ManagerProfileInfoQuery from "../../utils/reactQuery/manager/employeeDetail/ManagerProfileInfo";
import { PersonalInfoQuery } from "../../utils/reactQuery/profile/PersonalInfo";
import { ManagerContactInfoQuery } from "../../utils/reactQuery/manager/employeeDetail/ManagerContactInfo";
import { ContactInfoquery } from "../../utils/reactQuery/profile/ContactInfo";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import onBoardEmployeeSchema from "../../schemas/onBoardEmployee";
import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import onBoardEmployee from "../../services/manager/onBoard/apiOnBoardEmployee";
import { LoadingButton } from "@mui/lab";

const theme = createTheme({
  components: {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: "#1B3E61 !important",
          height: "4px", // Increase the height (thickness) of the indicator
        },
      },
    },
  },
});

export default function Profile({ openProp, handleClose, employeeId, isNew }) {
  const [activeTab, setActiveTab] = useState(0);

  const isMobile = useResponsive("down", "sm");

  const modalOverrideStyles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "330px" : 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    maxHeight: "90vh", // Ensures the modal doesn't exceed the viewport height
    overflowY: "auto",
  };

  const { employee: empPersonalInfo, isLoading: isLoadingPersonalInfo } =
    employeeId
      ? ManagerProfileInfoQuery(employeeId) // Gets the profile details of the employee under the manager
      : isNew
      ? {}
      : PersonalInfoQuery(); // Gets the profile details of the currently logged in user

  console.log(
    "isLoadingPersonalInfo, empPersonalInfo",
    isLoadingPersonalInfo,
    empPersonalInfo
  );

  const { contact: empContactInfo, isLoading: isLoadingContactInfo } =
    employeeId
      ? ManagerContactInfoQuery(employeeId) // Gets the profile details of the employee under the manager
      : isNew
      ? {}
      : ContactInfoquery(); // Gets the profile details of the currently logged in user

  console.log(
    "isLoadingContactInfo, empContactInfo",
    isLoadingContactInfo,
    empContactInfo
  );

  const methods = useForm({
    resolver: yupResolver(onBoardEmployeeSchema),
    defaultValues: {
      id: "",
      name: "",
      father_name: "",
      mother_name: "",
      dob: "",
      gender: "",
      qualification: "",
      experience: "",
      technology: "",
      pan_card: "",
      pf_account: "",
      esic_no: "",
      phone_number: "",
      alternate_phone_number: "",
      temporary_address: "",
      permanent_address: "",
      city: "",
      state: "",
      country: "",
      adhar_card: "",
      driver_license: "",
      profile_photo: "",
      fileuploads: [],
    },
  });

  const handleChange = (event, value) => {
    console.log("value", value);
    setActiveTab(value);
  };

  const handleNext = async () => {
    // const isValid = await methods.trigger([]);
    // if (isValid) {
    setActiveTab(1); // Move to ContactInfo if validation passes
    // }
  };

  const { mutate, isPending } = useMutation({
    mutationFn: onBoardEmployee,
    onSuccess: (data) => {
      console.log("success repsonse", data);
      toast.success(data.data.message);
      methods.reset();
      handleClose();
      setActiveTab(0);
    },
    onError: (data) => {
      console.log("error onBoarding employee", data.response.data);
    },
  });

  const onSubmit = (formData) => {
    console.log("Form Submitted:", formData);
    // Perform submit actions (API call, etc.)
    mutate(formData);
    // setActiveTab(0);
  };

  useEffect(() => {
    if (empPersonalInfo && empContactInfo) {
      methods.reset({
        id: empPersonalInfo?.id || "",
        name: empPersonalInfo?.name || "",
        father_name: empPersonalInfo?.father_name || "",
        mother_name: empPersonalInfo?.mother_name || "",
        dob: empPersonalInfo?.dob || "",
        gender: empPersonalInfo?.gender || "",
        qualification: empPersonalInfo?.qualification || "",
        experience: empPersonalInfo?.experience || "",
        technology: empPersonalInfo?.technology || "",
        pan_card: empPersonalInfo?.pan_card || "",
        pf_account: empPersonalInfo?.pf_account || "",
        esic_no: empPersonalInfo?.esic_no || "",
        phone_number: empContactInfo?.phone_number || "",
        alternate_phone_number: empContactInfo?.alternate_phone_number || "",
        temporary_address: empContactInfo?.temporary_address || "",
        permanent_address: empContactInfo?.permanent_address || "",
        city: empContactInfo?.city || "",
        state: empContactInfo?.state || "",
        country: empContactInfo?.country || "",
        adhar_card: empContactInfo?.adhar_card || "",
        driver_license: empContactInfo?.driver_license || "",
        profile_photo: null,
        fileuploads: [],
      });
    }
  }, [empContactInfo, empPersonalInfo, methods]);

  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={openProp}
        onClose={() => {
          handleClose();
          setActiveTab(0);
        }}
      >
        <Box sx={modalOverrideStyles} className={styles.container}>
          <Stack direction="row" className={styles.header}>
            <Typography className={styles.heading}>
              {isNew ? "OnBoard Employee" : "Profile Update"}
            </Typography>
            <Box
              onClick={() => {
                handleClose();
                setActiveTab(0);
              }}
            >
              <CloseIcon className={styles.icon} />
            </Box>
          </Stack>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Box className={styles.tabCont}>
                <Tabs
                  aria-label="profile tabs"
                  value={activeTab}
                  onChange={handleChange}
                  className={styles.tabContainer}
                >
                  <Tab
                    label="Personal Information"
                    className={styles.tabHead}
                    value={0}
                  />
                  <Tab
                    label="Contact Information"
                    className={styles.tabHead}
                    value={1}
                  />
                </Tabs>
              </Box>

              {/* Scrollable Content */}
              <Box className={styles["scrollable-content"]}>
                {activeTab === 0 ? (
                  <PersonalInfo
                    employeeId={empPersonalInfo?.id}
                    isLoading={isLoadingPersonalInfo}
                  />
                ) : (
                  <ContactInfo isLoading={isLoadingContactInfo} />
                )}
              </Box>

              {/* Fixed Buttons */}
              <Stack className={styles.btnCont}>
                {activeTab === 0 && (
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      type="button"
                      variant="contained"
                      className={styles.btn}
                      onClick={handleNext}
                    >
                      Next
                    </Button>
                  </Box>
                )}

                {activeTab === 1 && (
                  <Box display="flex" justifyContent="space-between">
                    <Button
                      type="button"
                      variant="contained"
                      className={styles.btn}
                      onClick={(e) => handleChange(e, 0)}
                    >
                      Back
                    </Button>
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      className={styles.btn}
                      loading={isPending}
                    >
                      Submit
                    </LoadingButton>
                  </Box>
                )}
              </Stack>
            </form>
          </FormProvider>
        </Box>
      </Modal>
    </ThemeProvider>
  );
}
