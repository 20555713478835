import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import styles from "./editHoliday.module.css";
import CloseIcon from "@mui/icons-material/Close";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import DatePickerComponent from "../DatePickerComp/DatePickerComp";
import RHFTextField from "../RFH/RFHtextfield.js";
import { LoadingButton } from "@mui/lab";
import { useResponsive } from "../../hooks/customUseResponsive.js";
import AddEditHolidaySchema from "../../schemas/addEditHolidaySchema.js";
import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { formatDate } from "../../utils/helpers.js";
import ViewHolidayQuery from "../../utils/reactQuery/ViewHoliday.js";
import { useEffect } from "react";
import dayjs from "dayjs";
import apiEditHoliday from "../../services/holiday/apiEditHoliday.js";
import Loader from "../Loader.js";

export default function EditHolidayModal({
  openProp,
  handleClose,
  id,
  refetch,
}) {
  const isMobile = useResponsive("down", "sm");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? 330 : 440,
    bgcolor: "#F8F9FA",
    boxShadow: 24,
    borderRadius: "10px",
  };

  const { holidayData, isLoading } = ViewHolidayQuery(id);
  console.log("holidayData", holidayData);

  const { mutate, isPending } = useMutation({
    mutationFn: apiEditHoliday,
    onSuccess: (data) => {
      toast.success(data.data.message);
      methods.reset();
      handleClose();
      refetch();
    },
    onError: (data) => {
      console.log("error editing holiday", data);
    },
  });

  const methods = useForm({
    resolver: yupResolver(AddEditHolidaySchema),
  });

  const onSubmit = (formData) => {
    console.log("formData === ", formData);
    const holidayDate = formatDate(formData.date);
    console.log("holidayDate", holidayDate);
    const holidaySubject = formData.subject;
    mutate({ id, holidayDate, holidaySubject });
  };

  useEffect(() => {
    if (holidayData) {
      methods.reset({
        date: dayjs(holidayData.date),
        subject: holidayData.subject,
      });
    }
  }, [holidayData, methods]);

  console.log("errors === ", methods.formState.errors);

  return (
    <Modal open={openProp} onClose={handleClose}>
      <Box sx={{ ...style }}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="row" className={styles.header}>
              <Typography className={styles.heading}>Edit Holiday</Typography>
              <Box onClick={() => handleClose()}>
                <CloseIcon className={styles.icon} />
              </Box>
            </Stack>
            <Stack className={styles.formCont}>
              {isLoading ? (
                <Stack className={styles.loaderCont}>
                  <Loader />
                </Stack>
              ) : (
                <>
                  <Box>
                    <Typography className={styles.label}>Date</Typography>
                    <Stack className={styles.inputFeild} direction="row">
                      <DatePickerComponent name="date" isFullWidth />
                    </Stack>
                  </Box>

                  <Box>
                    <Typography className={styles.label}>Message</Typography>
                    <RHFTextField
                      name="subject"
                      multiline
                      rows={4}
                      fullWidth
                      className={styles.textarea}
                      sx={{
                        "& .MuiInputBase-root": {
                          backgroundColor: "#fff !important",
                        },
                      }}
                    />
                  </Box>
                  <Stack alignItems="end">
                    <LoadingButton
                      loading={isPending}
                      variant="contained"
                      type="submit"
                      className={styles.btn}
                    >
                      Apply
                    </LoadingButton>
                  </Stack>
                </>
              )}
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </Modal>
  );
}
