import { useQuery } from "@tanstack/react-query";
import { getPersonalInfo } from "../../../services/profile/apiPersonalInfo";

export const PersonalInfoQuery = (currentEmployeeId) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ["personalInfo", currentEmployeeId],
    queryFn: () => getPersonalInfo(currentEmployeeId),
  });
  const employee = data?.data.employeeDetails;
  const documents = data?.data.documents[0];
  return { employee, documents, isLoading, isError };
};
