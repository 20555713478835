import styles from "./styles.module.css";
import { useRef, useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { LoadingButton } from "@mui/lab";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { leaveLogSchema as summaryReportSchema } from "../../schemas/leaveLog";
import DatePickerComponent from "../DatePickerComp/DatePickerComp";
import Loader from "../Loader";
import downloadIcon from "../../styles/icons/downloadIcon.svg";
import DataGridComp from "../DataGrid/DataGrid";
import { useResponsive } from "../../hooks/customUseResponsive";
import SummaryReportQuery from "../../utils/reactQuery/attendance/SummaryReport";

const DownloadButtonIcon = (
  <Box
    component="img"
    src={downloadIcon}
    alt="downloadIcon"
    height={28}
    width={28}
  />
);

export default function Summary({ currentEmployeeId }) {
  const date = new Date();
  const [isGenerating, setIsGenerating] = useState(false);
  const [previewPdf, setPreviewPdf] = useState(false);
  const isMobile = useResponsive("down", "sm");

  // Calculate start and end dates
  const startDate = useRef(
    new Date(date.getFullYear(), date.getMonth(), date.getDate() - 5)
      .toISOString()
      .split("T")[0] // Extract just the date part in "YYYY-MM-DD" format
  );
  const endDate = useRef(date.toISOString().split("T")[0]);

  console.log("startDate:", startDate.current);
  console.log("endDate:", endDate.current);

  const { summaryReport, isLoading } = SummaryReportQuery(
    currentEmployeeId,
    startDate.current,
    endDate.current
  );

  const columns = [
    {
      field: "col1",
      headerName: "Employee Name",
      minWidth: isMobile ? 150 : 200,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col2",
      headerName: "Month & Year",
      minWidth: isMobile && 120,
      flex: 1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col3",
      headerName: "Total Days",
      minWidth: isMobile && 120,
      sortable: false,
      flex: 1,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col4",
      headerName: "Working Days",
      minWidth: isMobile && 120,
      sortable: false,
      flex: 1,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col5",
      headerName: "Present Days",
      minWidth: isMobile && 120,
      sortable: false,
      flex: 1,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col6",
      headerName: "Off Days",
      minWidth: isMobile && 100,
      sortable: false,
      flex: 1,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col7",
      headerName: "Casual",
      minWidth: isMobile && 100,
      sortable: false,
      flex: 1,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col8",
      headerName: "Medical",
      minWidth: isMobile && 100,
      sortable: false,
      flex: 1,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col9",
      headerName: "Weekend Working",
      minWidth: isMobile && 120,
      sortable: false,
      flex: 1,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "col10",
      headerName: "Avg. Hrs/Days",
      minWidth: isMobile && 120,
      sortable: false,
      flex: 1,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
  ];

  const methods = useForm({
    resolver: yupResolver(summaryReportSchema),
  });

  const onSubmit = async (formData) => {
    setIsGenerating(true);

    const start_date = `${formData.startDate.getFullYear()}-${
      formData.startDate.getMonth() + 1
    }-${formData.startDate.getDate()}`;

    const end_date = `${formData.endDate.getFullYear()}-${
      formData.endDate.getMonth() + 1
    }-${formData.endDate.getDate()}`;

    startDate.current = start_date;
    endDate.current = end_date;

    // refetch();

    setIsGenerating(false);
  };

  const handlePreviewPdf = () => {
    setPreviewPdf(true);
    const previewUrl = "/summary-report-preview";

    // Store the data in localStorage
    localStorage.setItem("summaryReportPdfData", JSON.stringify(summaryReport));
    localStorage.setItem("fromDate", startDate.current);
    localStorage.setItem("toDate", endDate.current);

    // Create a new tab and pass the state
    window.open(previewUrl, "_blank");

    setPreviewPdf(false);
  };

  return (
    <>
      <Box paddingBottom={1} minHeight={250}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="row" className={styles.formContainer}>
              <Stack className={styles.dateSection}>
                <Typography className={styles.datePickerlabel}>
                  From Date
                </Typography>

                <DatePickerComponent name="startDate" />
              </Stack>

              <Stack className={styles.dateSection}>
                <Typography className={styles.datePickerlabel}>
                  To Date
                </Typography>
                <DatePickerComponent name="endDate" />
              </Stack>

              <LoadingButton
                loading={isGenerating}
                type="submit"
                variant="contained"
                className={styles.generateBtn}
              >
                Generate Report
              </LoadingButton>

              <LoadingButton
                variant="contained"
                startIcon={DownloadButtonIcon}
                className={styles.downloadButton}
                loading={previewPdf}
                onClick={handlePreviewPdf}
              >
                Download Pdf
              </LoadingButton>
            </Stack>
          </form>
        </FormProvider>
        <Box className={styles.dataGridContainer}>
          {isLoading && (
            <Stack className={styles.loaderCont}>
              <Loader />
            </Stack>
          )}
          <DataGridComp
            columns={columns || []}
            initialRows={summaryReport || []}
            footer={false}
          />
        </Box>
      </Box>
    </>
  );
}
