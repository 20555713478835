import { useState } from "react";
import { Box, Button, Popover } from "@mui/material";

export default function DropdownPopover({ buttonLabel, children }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box>
      {/* Button to open the dropdown */}
      <Button onClick={handleClick} variant="outlined" sx={{ borderRadius: 5 }}>
        {buttonLabel}
      </Button>

      {/* Popover acting as a dropdown */}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box p={2} sx={{ minWidth: 50 }}>
          {children}
        </Box>
      </Popover>
    </Box>
  );
}
