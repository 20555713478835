import { Box } from "@mui/material";
import CardSection from "../CardSection/CardSection";
import styles from "./styles.module.css";
import TableHeader from "../../../components/TableHeader/TableHeader";
import { useState } from "react";
import OnBoard from "../../../components/OnBoard/OnBoard";
import AllEmployees from "./AllEmployees";
import { localStorageInstance } from "../../../utils/localStorage";

export default function TeamDashboard() {
  const currentUser = localStorageInstance.getItem("user");
  console.log("TeamDashboard currentUser", currentUser);

  const [onBoard, setOnBoard] = useState(false);

  const handleModalClose = () => {
    setOnBoard(!onBoard);
  };

  return (
    <>
      <CardSection />
      <Box className={styles.mainContainer}>
        <TableHeader
          heading="All Employees"
          btntxt="Onboard Employee"
          handleModal={handleModalClose}
        />
        <AllEmployees />
      </Box>
      <OnBoard
        openProp={onBoard}
        handleClose={handleModalClose}
        // currentEmployeeId={currentUser.employee_id}
      />
    </>
  );
}
