import { useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TableHeader from "../TableHeader/TableHeader";
import DataGridComp from "../DataGrid/DataGrid";
import ConfirmModal from "../ConfirmationModal/ConfirmModal";
import { useResponsive } from "../../hooks/customUseResponsive";
import { usDateFormate } from "../../hooks/dateFormat";
import editIcon from "../../styles/icons/editIcon.svg";
import deleteIcon from "../../styles/svg/deleteIcon.svg";
import styles from "./styles.module.css";
import EditHolidayModal from "./EditHoliday";
import HolidayListQuery from "../../utils/reactQuery/HolidayList";
import AddHolidayModal from "./AddHoliday";
import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import Loader from "../Loader";
import apiDeleteHoliday from "../../services/holiday/apiDeleteHoliday";

export default function HolidayList() {
  const { holidayList, isLoading, refetch } = HolidayListQuery();
  const [addHoliday, setAddHoliday] = useState(false);
  const [editHoliday, setEditHoliday] = useState(false);
  const [deleteHoliday, setDeleteHoliday] = useState(false);
  const [holidayId, setHolidayId] = useState("");
  const isMobile = useResponsive("down", "sm");

  const { mutate } = useMutation({
    mutationFn: apiDeleteHoliday,
    onSuccess: (data) => {
      toast.success(data.data.message);
      refetch();
    },
    onError: (data) => {
      console.log("error deleting holiday", data);
    },
  });

  const handleEdit = (id) => {
    setHolidayId(id);
    setEditHoliday(true);
  };

  const handleDelete = (id) => {
    setHolidayId(id);
    setDeleteHoliday(true);
  };

  const handleConfirm = (id) => {
    console.log("confirm delete id", id);
    mutate({ id });
    setDeleteHoliday(false);
  };

  const columns = [
    {
      field: "holidayDate",
      headerName: "Create Date",
      minWidth: isMobile ? 100 : 161,
      flex: 1.1,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{usDateFormate(value.value)}</span>,
    },
    {
      field: "subject",
      headerName: "Subject",
      minWidth: isMobile ? 150 : 250,
      flex: 2,
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
    },
    {
      field: "holidayId",
      headerName: "Actions",
      minWidth: isMobile ? 100 : 123,
      sortable: false,
      flex: 0.8,
      resizable: false,
      renderCell: (value) => {
        return (
          <Stack gap={1} className={styles.viewCont}>
            <Box
              component="img"
              src={editIcon}
              alt="edit"
              className={styles.icon}
              onClick={() => handleEdit(value.value)}
            />
            <Box
              component="img"
              src={deleteIcon}
              alt="delete"
              className={styles.icon}
              onClick={() => handleDelete(value.value)}
            />
          </Stack>
        );
      },
    },
  ];

  return (
    <Box className={styles.mainContainer}>
      {/* For the scrolling of the sections */}
      <span id="holidays" className={styles.holidayContainer}></span>
      <TableHeader
        heading="Holiday List"
        btnicon="ic:outline-plus"
        handleModal={() => setAddHoliday(true)}
      />
      <Box className={styles.datagridCont}>
        {isLoading && (
          <Stack className={styles.loaderCont}>
            <Loader />
          </Stack>
        )}
        {/* {holidayList && ( */}
          <DataGridComp columns={columns} initialRows={holidayList || []} />
        {/* )} */}
      </Box>

      {addHoliday && (
        <AddHolidayModal
          openProp={addHoliday}
          handleClose={() => setAddHoliday(false)}
          refetch={refetch}
        />
      )}

      {editHoliday && (
        <EditHolidayModal
          openProp={editHoliday}
          handleClose={() => setEditHoliday(false)}
          id={holidayId}
          refetch={refetch}
        />
      )}

      {deleteHoliday && (
        <ConfirmModal
          openProp={deleteHoliday}
          handleClose={() => setDeleteHoliday(false)}
          handleConfirm={() => handleConfirm(holidayId)}
          tabHeader="Delete Holiday"
          heading="Delete Holiday ?"
          content="Are you sure you want to delete this holiday? This action can not be undone."
          btnText="Delete"
        />
      )}
    </Box>
  );
}
