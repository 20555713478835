import { useQuery } from "@tanstack/react-query";
import { getLeaveStatement } from "../../../services/leave/apiLeaveStatement";
import toast from "react-hot-toast";

const monthNames = [
  "",
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const LeaveStatementQuery = (financial_year, employee_id) => {
  const { data, isLoading, error } = useQuery({
    queryKey: ["leaveStatement", { financial_year, employee_id }],
    queryFn: () => getLeaveStatement({ financial_year, employee_id }),
  });

  if (error) {
    toast.error(error?.response?.data.error);
  }

  console.log("leave statement data", data?.data);

  let leaveStatement = data?.data.map((ele, idx) => {
    return {
      name: ele.employee.split("-")[0],
      leaveBalance: ele.leave_balances.map((item, idx) => ({
        id: idx,
        col1: `${monthNames[item.month]} ${item.year}`,
        col2: item.previous_cl_balance,
        col3: item.cl_allotted,
        col4: item.cl_used,
        col5: item.late_coming,
        col6: item.early_going,
        col7: item.lwp,
        col8: item.encashed,
        col9: item.compoff_adjusted,
        col10: item.cl_closing_balance,
      })),
    };
  });

  // console.log("leaveStatement before return", leaveStatement);

  return { leaveStatement, isLoading };
};
