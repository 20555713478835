import { useRef, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import styles from "./styles.module.css";
import TableHeader from "../../../components/TableHeader/TableHeader";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import InOutModal from "../../../components/InOutModal/InOutModal";
import forwardIcon from "../../../styles/icons/forward.svg";
import backwardIcon from "../../../styles/icons/back.svg";
import { AttendanceQuery } from "../../../utils/reactQuery/attendance/AttendanceData";
import { useResponsive } from "../../../hooks/customUseResponsive";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import Iconify from "../../../components/Icon/Iconify";
import { addZeroToSingleDigits } from "../../../utils/helpers";
import AddAttendanceModal from "../../ManageTeam/AttendanceRequest.js/AddAttendance";

export default function Attendance() {
  const calendarRef = useRef(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [openInOutModal, setInOutModal] = useState(false);
  const [addAttendanceModal, setAddAttendanceModal] = useState(false);
  const [dateInOut, setDateInOut] = useState("");
  const [inoutWeek, setInoutWeek] = useState("");
  const isMobile = useResponsive("down", "sm");

  // Fetch attendance data
  const { attendanceList: event, isLoading } = AttendanceQuery({
    year: currentDate.getFullYear(),
    month: currentDate.getMonth() + 1,
  });

  console.log("attendanceList", event);

  // Month names array for display purposes
  const monthNames = [
    "",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Handle calendar dates set event
  const handleDatesSet = (dateInfo) => {
    setCurrentDate(new Date());
  };

  // Handle previous month button click
  const handlePrevClick = () => {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.prev();
    setCurrentDate(calendarApi.getDate());
  };

  // Handle next month button click
  const handleNextClick = () => {
    let calendarApi = calendarRef.current.getApi();
    calendarApi.next();
    setCurrentDate(calendarApi.getDate());
  };

  // Get current month and year
  const getCurrentMonthYear = () => {
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();
    return { month, year };
  };

  const { month, year } = getCurrentMonthYear();

  // Render event content in the calendar
  function renderEventContent(eventInfo) {
    console.log("event === ", eventInfo);
    const handleInOutModal = (date) => {
      setInOutModal(true);
      setDateInOut(date);
      setInoutWeek(eventInfo.event.start.getDay());
    };
    return (
      <>
        <Typography
          onClick={() => handleInOutModal(eventInfo.event.startStr)}
          className={`${styles.statusChip} ${
            eventInfo.event.title === "Leave"
              ? styles.leaveStatus
              : eventInfo.event.title === "Holiday"
              ? styles.holidayStatus
              : styles.missedEntry
          }`}
        >
          {isMobile ? (
            eventInfo.event.title === "Leave" ? (
              "LE"
            ) : eventInfo.event.title === "Holiday" ? (
              "HD"
            ) : eventInfo.event.title === "Short Day" ? (
              "SD"
            ) : eventInfo.event.title === "Present" ? (
              "PR"
            ) : eventInfo.event.title === "Comp_Off" ? (
              "CO"
            ) : (
              "ME"
            )
          ) : (
            <>
              {eventInfo.event.title === "Leave" ||
              eventInfo.event.title === "Holiday" ? (
                eventInfo.event.title
              ) : (
                <Box display="flex" alignItems="center">
                  {eventInfo.event.extendedProps.isLateComing && (
                    <WarningRoundedIcon sx={{ color: "#FF0000" }} />
                  )}
                  {eventInfo.event.extendedProps.breakCount > 0 && (
                    <Box display="flex" alignItems="center">
                      <Iconify
                        icon="solar:tea-cup-bold"
                        sx={{ color: "#FFBB00" }}
                      />
                      {addZeroToSingleDigits(
                        eventInfo.event.extendedProps.breakCount
                      )}
                    </Box>
                  )}
                  {eventInfo.event.extendedProps.oneWayEntryCount > 0 && (
                    <Box display="flex" alignItems="center">
                      <Iconify icon="bxs:door-open" sx={{ color: "#AEB7BB" }} />
                      {addZeroToSingleDigits(
                        eventInfo.event.extendedProps.oneWayEntryCount
                      )}
                    </Box>
                  )}
                  {eventInfo.event.extendedProps.shortHoursCount > 0 && (
                    <Box display="flex" alignItems="center">
                      <Iconify
                        icon="fluent:person-clock-24-filled"
                        sx={{ color: "#5CCBFF" }}
                      />
                      {addZeroToSingleDigits(
                        eventInfo.event.extendedProps.shortHoursCount
                      )}
                    </Box>
                  )}
                  {!(
                    eventInfo.event.extendedProps.isLateComing ||
                    eventInfo.event.extendedProps.breakCount > 0 ||
                    eventInfo.event.extendedProps.oneWayEntryCount > 0 ||
                    eventInfo.event.extendedProps.shortHoursCount > 0
                  ) && <span>Present</span>}
                </Box>
              )}
            </>
          )}
        </Typography>
      </>
    );
  }

  return (
    <Box className={styles.mainContainer}>
      <TableHeader
        heading={"Attendance"}
        btntxt="Add Attendance"
        handleModal={() => {
          setAddAttendanceModal(true);
        }}
      />
      <Box className={styles.initialsCont}>
        <Stack className={styles.shortFormCont} direction="row">
          {isMobile ? (
            <>
              <Stack direction="row" className={styles.subCont}>
                <Typography className={styles.shortFormText}>
                  <b>PR </b>- Present
                </Typography>
              </Stack>
              <Stack direction="row" className={styles.subCont}>
                <Typography className={styles.shortFormText}>
                  <b>LE </b>- Leave
                </Typography>
              </Stack>
              <Stack direction="row" className={styles.subCont}>
                <Typography className={styles.shortFormText}>
                  <b>SD </b>- Short Days
                </Typography>
              </Stack>
              <Stack direction="row" className={styles.subCont}>
                <Typography className={styles.shortFormText}>
                  <b>ME </b>- Missing Entry
                </Typography>
              </Stack>
              <Stack direction="row" className={styles.subCont}>
                <Typography className={styles.shortFormText}>
                  <b>CO </b>- Comp Off
                </Typography>
              </Stack>
              <Stack direction="row" className={styles.subCont}>
                <Typography className={styles.shortFormText}>
                  <b>HD </b>- Holiday
                </Typography>
              </Stack>
            </>
          ) : (
            <>
              <Stack direction="row" className={styles.subCont}>
                <Iconify icon="solar:tea-cup-bold" sx={{ color: "#FFBB00" }} />
                <Typography className={styles.shortFormText}>
                  Break Time
                </Typography>
              </Stack>

              <Stack direction="row" className={styles.subCont}>
                <WarningRoundedIcon sx={{ color: "#FF0000" }} />
                <Typography className={styles.shortFormText}>
                  Late Coming
                </Typography>
              </Stack>

              <Stack direction="row" className={styles.subCont}>
                <Iconify icon="bxs:door-open" sx={{ color: "#AEB7BB" }} />
                <Typography className={styles.shortFormText}>
                  One Way Entry
                </Typography>
              </Stack>

              <Stack direction="row" className={styles.subCont}>
                <Iconify
                  icon="fluent:person-clock-24-filled"
                  sx={{ color: "#5CCBFF" }}
                />
                <Typography className={styles.shortFormText}>
                  Short Hours
                </Typography>
                <Typography></Typography>
              </Stack>
            </>
          )}
        </Stack>
      </Box>
      <Box className={styles.tabContainer}>
        <Stack className={styles.calendarCont}>
          <Stack className={styles.monthYearCont}>
            <Typography className={styles.month}>
              {monthNames[month]}
            </Typography>
            <Typography className={styles.year}>{year}</Typography>
          </Stack>

          <Stack className={styles.calenderBtn}>
            <Button
              disabled={isLoading}
              className={styles.arrowBtn}
              onClick={handlePrevClick}
            >
              <img className={styles.icons} src={backwardIcon} alt="backward" />
            </Button>

            <Button
              disabled={
                isLoading || currentDate.getMonth() === new Date().getMonth()
              }
              className={
                currentDate.getMonth() === new Date().getMonth()
                  ? styles.disableNextArrow
                  : styles.arrowBtn
              }
              onClick={handleNextClick}
            >
              <img className={styles.icons} src={forwardIcon} alt="forward" />
            </Button>
          </Stack>
        </Stack>

        {isLoading && (
          <Stack className={styles.calendarLoader}>
            <CircularProgress />
          </Stack>
        )}

        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, listPlugin, timeGridPlugin]}
          initialView="dayGridMonth"
          headerToolbar={false}
          datesSet={handleDatesSet}
          events={event}
          eventContent={renderEventContent}
          fixedWeekCount={false}
        />
      </Box>
      {openInOutModal && (
        <InOutModal
          openProp={openInOutModal}
          handleClose={() => setInOutModal(false)}
          date={dateInOut}
          day={inoutWeek}
        />
      )}
      {addAttendanceModal && (
        <AddAttendanceModal
          openProp={addAttendanceModal}
          handleClose={() => setAddAttendanceModal(false)}
        />
      )}
    </Box>
  );
}
