import { useQuery } from "@tanstack/react-query";
import apiViewHoliday from "../../services/holiday/apiViewHoliday";

export default function ViewHolidayQuery(holidayId) {
  const { data, isLoading } = useQuery({
    queryKey: ["viewHoliday", holidayId],
    queryFn: () => apiViewHoliday(holidayId),
  });

  console.log("view holiday data", data);

  const holidayData = data?.data[0];

  return { holidayData, isLoading };
}
