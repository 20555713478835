import * as Yup from "yup";

const onBoardEmployeeSchema = Yup.object().shape({
  name: Yup.string().required("Employee name is required"),
  father_name: Yup.string(),
  mother_name: Yup.string(),
  dob: Yup.string(),
  gender: Yup.string(),
  qualification: Yup.string(),
  experience: Yup.string(),
  technology: Yup.string(),
  pan_card: Yup.string(),
  pf_account: Yup.string(),
  esic_no: Yup.string(),
  phone_number: Yup.string(),
  alternate_phone_number: Yup.string(),
  temporary_address: Yup.string(),
  permanent_address: Yup.string(),
  city: Yup.string(),
  state: Yup.string(),
  country: Yup.string(),
  adhar_card: Yup.string(),
  driver_license: Yup.string(),
  profile_photo: Yup.mixed(),
  fileuploads: Yup.mixed(),
});

export default onBoardEmployeeSchema;
