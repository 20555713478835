import { useQuery } from "@tanstack/react-query";
import apiManagersList from "../../services/manager/apiManagersList";

export default function ManagersListQuery() {
  const { data } = useQuery({
    queryKey: ["managersList"],
    queryFn: () => apiManagersList(),
  });

  const managers = data?.data.manager;
  console.log("ManagersListQuery data", managers);

  let managersList = managers?.map((manager, idx) => ({
    value: manager.employee_id,
    label: manager.name,
  }));

  return null;
}
