// DatePickerComponent.jsx
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers';
import { useResponsive } from '../../hooks/customUseResponsive';

const DatePickerComponent = ({ name, isFullWidth }) => {
    const { control} = useFormContext();
    const isMobile = useResponsive('down','sm');
  
    return (
      <Controller
        name={name}
        control={control}
        defaultValue={null}
        render={({ field, fieldState: { error } }) => (
          <DatePicker
            sx={{
              "& .MuiInputBase-root": {
                height: isMobile ? "30px !important" : "40px !important",
                backgroundColor: "#fff",
                fontSize: isMobile ? "12px" : "",
              },
            }}
            slotProps={{
              textField: {
                fullWidth: isMobile || !isFullWidth ? false : true,
                variant: "outlined",
                error: !!error,
                helperText: error?.message,
                style: {
                  height: isMobile ? "30px !important" : "40px !important",
                  fontSize: isMobile ? "12px" : "",
                }, // Adjust the height here
              },
            }}
            {...field}
          />
        )}
      />
    );
  };
  

export default DatePickerComponent;
