import { Autocomplete, Box, Stack, TextField } from "@mui/material";
import styles from "./styles.module.css";
import Loader from "../../../components/Loader";
import { useState } from "react";
import DataGridComp from "../../../components/DataGrid/DataGrid";
import useEmployeeSelection from "../../../hooks/useEmployeeSelection";
import Iconify from "../../../components/Icon/Iconify";
import { RFHMultiCheckbox } from "../../../components/RFH/RFHMultiCheckbox";
import { FormProvider, useForm } from "react-hook-form";
import DropdownPopover from "../../../components/DropDownPopover/DropDownPopover";
import AllEmployeeReportQuery from "../../../utils/reactQuery/manager/employeeDetail/AllEmployeeReport";
import { useResponsive } from "../../../hooks/customUseResponsive";

const options = [
  { label: "Option 1", value: "1" },
  { label: "Option 2", value: "2" },
  { label: "Option 3", value: "3" },
];

export default function AllEmployees() {
  const isMobile = useResponsive("down", "sm");
  const { emplist } = useEmployeeSelection();
  console.log("AllEmployees emplist", emplist);

  const { report, isLoading } = AllEmployeeReportQuery();

  const [selectedOption, setSelectedOption] = useState(null);
  console.log("selectedOption", selectedOption);

  const handleChange = (event, newValue) => {
    setSelectedOption(newValue);
  };

  const defaultValues = {
    abc: "",
    def: "",
    ghi: "",
  };

  const methods = useForm({
    defaultValues,
  });

  const columns = [
    {
      field: "col1",
      headerName: "Employee Name",
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
      minWidth: isMobile ? 70 : 200,
    },
    {
      field: "col2",
      headerName: "Manager",
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
      minWidth: isMobile ? 70 : 200,
    },
    {
      field: "col3",
      headerName: "Team",
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
      minWidth: isMobile ? 70 : 150,
    },
    {
      field: "col4",
      headerName: "P",
      headerAlign: "right",
      align: "right",
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
      maxWidth: isMobile ? 70 : 74,
      headerClassName: "spacing",
    },
    {
      field: "col5",
      headerName: "A",
      headerAlign: "right",
      align: "right",
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
      maxWidth: isMobile ? 70 : 74,
      headerClassName: "spacing",
    },
    {
      field: "col6",
      headerName: "R",
      headerAlign: "right",
      align: "right",
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
      maxWidth: isMobile ? 70 : 74,
      headerClassName: "spacing",
    },
    {
      field: "col7",
      headerName: "LWP",
      headerAlign: "right",
      align: "right",
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
      maxWidth: isMobile ? 70 : 74,
      headerClassName: "spacing",
    },
    {
      field: "col8",
      headerName: "In/Out",
      headerAlign: "right",
      align: "right",
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
      maxWidth: isMobile ? 70 : 74,
      headerClassName: "spacing",
    },
    {
      field: "col9",
      headerName: "WFH",
      headerAlign: "right",
      align: "right",
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
      maxWidth: isMobile ? 70 : 74,
      headerClassName: "spacing",
    },
    {
      field: "col10",
      headerName: "Comp Off",
      headerAlign: "right",
      align: "right",
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
      maxWidth: isMobile ? 70 : 74,
      headerClassName: "spacing",
    },
    {
      field: "col11",
      headerName: "Short Days",
      headerAlign: "right",
      align: "right",
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
      maxWidth: isMobile ? 70 : 74,
      headerClassName: "spacing",
    },
    {
      field: "col12",
      headerName: "Breaks",
      headerAlign: "right",
      align: "right",
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
      maxWidth: isMobile ? 70 : 74,
      headerClassName: "spacing",
    },
    {
      field: "col13",
      headerName: "Avg. Hours",
      headerAlign: "right",
      align: "right",
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
      maxWidth: isMobile ? 70 : 74,
      headerClassName: "spacing",
    },
    {
      field: "col14",
      headerName: "Late",
      headerAlign: "right",
      align: "right",
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
      maxWidth: isMobile ? 70 : 74,
      headerClassName: "spacing",
    },
    {
      field: "col15",
      headerName: "Leave Taken",
      headerAlign: "right",
      align: "right",
      sortable: false,
      resizable: false,
      renderCell: (value) => <span>{value.value}</span>,
      maxWidth: isMobile ? 70 : 74,
      headerClassName: "spacing",
    },
  ];

  const columnGroupingModel = [
    {
      groupId: "Employee Info",
      headerName: "Employee Info",
      children: [
        { field: "col1" }, // Employee Name column
      ],
    },
    {
      groupId: "Management",
      headerName: "Manager",
      children: [{ field: "col2" }],
    },
    {
      groupId: "Team Info",
      headerName: "Team",
      children: [{ field: "col3" }],
    },
    {
      groupId: "Upcoming Leaves",
      description: "",
      children: [
        { field: "col4" },
        { field: "col5" },
        { field: "col6" },
        { field: "col7" },
      ],
    },
    {
      groupId: "Pending Attendance",
      description: "",
      children: [{ field: "col8" }, { field: "col9" }],
    },
    {
      groupId: "Comp Off",
      children: [{ field: "col10" }],
    },
    {
      groupId: "Last 30 days",
      description: "",
      children: [
        { field: "col11" },
        { field: "col12" },
        { field: "col13" },
        { field: "col14" },
        { field: "col15" },
      ],
    },
  ];

  return (
    <>
      <FormProvider {...methods}>
        <form>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            paddingX={4}
            paddingY={2}
          >
            <Box width={350}>
              <Autocomplete
                options={emplist}
                getOptionLabel={(option) => option.name} // Define how the options should be displayed
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=""
                    variant="outlined"
                    placeholder="Search..."
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <Iconify
                          icon="ph:magnifying-glass-bold"
                          color="#B9B9B9"
                        />
                      ),
                    }}
                  />
                )}
                value={selectedOption}
                onChange={handleChange}
                isOptionEqualToValue={(option, value) =>
                  option.value === value?.employee_id
                }
                fullWidth
              />
            </Box>
            <Box display="flex" alignItems="center" gap={2}>
              <DropdownPopover buttonLabel="Team">
                <RFHMultiCheckbox name="abc" options={options} />
              </DropdownPopover>
              <DropdownPopover buttonLabel="Manager">
                <RFHMultiCheckbox name="def" options={options} />
              </DropdownPopover>
              <DropdownPopover buttonLabel="Status">
                <RFHMultiCheckbox name="ghi" options={options} />
              </DropdownPopover>
              <DropdownPopover buttonLabel="Avg. Working Hours"></DropdownPopover>
            </Box>
          </Box>
        </form>
      </FormProvider>

      <Box
        className={styles.dataGridContainer}
        sx={{
          "& .spacing": {
            paddingLeft: "14px !important",
          },
        }}
      >
        {isLoading && (
          <Stack className={styles.loaderCont}>
            <Loader />
          </Stack>
        )}
        <DataGridComp
          columns={columns || []}
          initialRows={report || []}
          columnGroupingModel={columnGroupingModel || []}
          footer={false}
        />
      </Box>
    </>
  );
}
