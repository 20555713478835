import { useQuery } from "@tanstack/react-query";
import apiAttendanceRegister from "../../../services/attendance/apiAttendanceRegister";

function assignCellClassName(value) {
  switch (value) {
    case "P":
      return "presentText";
    case "H":
      return "holidayText";
    case "W":
      return "weekendText";
    case "A":
      return "absentText";
    case "Leave":
      return "absentText";
    default:
      return "presentText";
  }
}

export default function AttendanceRegisterQuery(
  employeeId,
  startDate,
  endDate
) {
  console.log("employeeId,startDate,endDate", employeeId, startDate, endDate);
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["attendance-register", employeeId, startDate, endDate],
    queryFn: () => apiAttendanceRegister(employeeId, startDate, endDate),
  });

  console.log("apiAttendanceRegister data", data);

  const rows = data?.data.map((employeeData, idx) => {
    const attendance = employeeData.attendance_record;
    console.log("attendance", attendance);

    return {
      id: idx,
      "Employee Name": employeeData.employee_name,
      ...attendance,
    };
  });

  console.log("rows", rows);

  const attendanceColumnNames = data?.data?.[0]?.attendance_record ?? {};
  console.log("attendanceColumnNames", attendanceColumnNames);
  const initialColumns = Object.keys(attendanceColumnNames);
  initialColumns.unshift("Employee Name");

  console.log("initialColumns", initialColumns);

  const updatedColumns = initialColumns.map((col, idx) => ({
    field: col,
    headerName: col,
    minWidth: idx === 0 ? 150 : 100,
    flex: 1,
    sortable: false,
    resizable: false,
    renderCell: (value) => <span>{value.value}</span>,
    cellClassName: (value) => {
      console.log("param", value);
      return assignCellClassName(value.value);
    },
  }));

  console.log("updatedColumns", updatedColumns);

  return { updatedColumns, rows, isLoading, refetch };
}
