import * as Yup from "yup";

const employeeDetailsSchema = Yup.object({
  name: Yup.string()
    .trim()
    .required("Name is required")
    .max(50, "Name cannot exceed 50 characters"),

  graduationYear: Yup.number()
    .typeError("Graduation Year must be a number")
    .integer("Graduation Year must be an integer")
    .min(1900, "Enter a valid year")
    .max(new Date().getFullYear(), "Graduation Year cannot be in the future"),

  education: Yup.string()
    .trim()
    .required("Education is required")
    .max(100, "Education details cannot exceed 100 characters"),

  experienceYear: Yup.string().trim(),

  experienceMonth: Yup.string().trim(),

  phoneNumber: Yup.string()
    .trim()
    .matches(/^[0-9]{10}$/, "Mobile number must be exactly 10 digits"),

  employeeId: Yup.string().trim(),
  // .required("Employee ID is required"),

  designation: Yup.string()
    .trim()
    .required("Designation is required")
    .max(50, "Designation cannot exceed 50 characters"),

  technology: Yup.string()
    .trim()
    .max(50, "Technology cannot exceed 50 characters"),
  // .required("Technology is required")

  managerId: Yup.string()
    .trim()
    .required("Manager is required")
    .max(5, "ManagerId cannot exceed 5 characters"),

  joiningDate: Yup.date()
    .typeError("Invalid Joining Date")
    .required("Joining Date is required"),

  relievingDate: Yup.date()
    .nullable()
    .typeError("Invalid Relieving Date")
    .min(
      Yup.ref("joiningDate"),
      "Relieving Date cannot be before Joining Date"
    ),

  documentLink: Yup.string().url("Enter a valid URL"),

  gender: Yup.string().oneOf(["Male", "Female"], "Invalid gender option"),

  dob: Yup.date()
    .typeError("Invalid Date of Birth")
    .required("Date of Birth is required"),

  currentAddress: Yup.string()
    .required("Current Address is required")
    .max(200, "Address cannot exceed 200 characters"),

  currentCity: Yup.string()
    .required("Current City is required")
    .max(50, "City cannot exceed 50 characters"),

  currentState: Yup.string()
    .required("Current State is required")
    .max(50, "State cannot exceed 50 characters"),

  currentZip: Yup.string().matches(/^[0-9]{5,6}$/, "Enter a valid ZIP code"),

  permanentAddress: Yup.string()
    .required("Permanent Address is required")
    .max(200, "Address cannot exceed 200 characters"),

  permanentCity: Yup.string()
    .required("Permanent City is required")
    .max(50, "City cannot exceed 50 characters"),

  permanentState: Yup.string()
    .required("Permanent State is required")
    .max(50, "State cannot exceed 50 characters"),

  permanentZip: Yup.string().matches(/^[0-9]{5,6}$/, "Enter a valid ZIP code"),

  adharCard: Yup.string()
    .trim()
    .matches(/^[0-9]{12}$/, "Aadhar must be a 12-digit number"),

  panCard: Yup.string()
    .trim()
    .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Enter a valid PAN number"),

  driverLicense: Yup.string()
    .trim()
    .max(20, "Driving License cannot exceed 20 characters"),

  pfAccount: Yup.string()
    .trim()
    .max(25, "PF Account cannot exceed 25 characters"),

  esicNo: Yup.string().trim().max(25, "ESIC cannot exceed 25 characters"),

  ldap: Yup.string().trim().max(20, "LDAP cannot exceed 20 characters"),

  fatherName: Yup.string()
    .trim()
    .required("Father's Name is required")
    .max(50, "Father's Name cannot exceed 50 characters"),

  motherName: Yup.string()
    .trim()
    .required("Mother's Name is required")
    .max(50, "Mother's Name cannot exceed 50 characters"),

  documents: Yup.mixed()
    .nullable()
    .test(
      "fileSize",
      "File size is too large",
      (value) => !value || value.size <= 1024 * 1024
    )
    .test(
      "fileType",
      "Unsupported File Format",
      (value) =>
        !value || ["image/jpg", "image/jpeg", "image/png"].includes(value.type)
    ),

  status: Yup.string()
    .required("Status is required")
    .oneOf(["Active", "Inactive"], "Invalid status option"),

  permission: Yup.array().of(Yup.string()).required("Permissions are required"),
});

export default employeeDetailsSchema;
