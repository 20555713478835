import { PDFViewer } from "@react-pdf/renderer";
import LeaveStatementPdf from "./LeaveStatementPdf";
import { useEffect, useState } from "react";

export default function LeaveStatementPdfPreview() {
  const [pdfData, setPdfData] = useState(null);
  const [currentYear, setCurrentYear] = useState(null);

  useEffect(() => {
    // Retrieve the data from localStorage
    const storedPdfData = localStorage.getItem("pdfData");
    const storedCurrentYear = localStorage.getItem("currentYear");

    if (storedPdfData && storedCurrentYear) {
      setPdfData(JSON.parse(storedPdfData));
      setCurrentYear(storedCurrentYear);
    }

    localStorage.removeItem("pdfData");
    localStorage.removeItem("currentYear");
  }, []);

  console.log("pdfdata preview", currentYear, pdfData);

  return (
    <PDFViewer className="pdfViewer">
      <LeaveStatementPdf pdfData={pdfData} currentYear={currentYear} />
    </PDFViewer>
  );
}
