import { useQuery } from "@tanstack/react-query";
import apiRunJob from "../../services/manager/apiRunJob";

export default function RunJobQuery() {
  const { data } = useQuery({
    queryKey: ["runJob"],
    queryFn: () => apiRunJob(),
  });

  const message = data?.data.message;
  console.log("RunJobQuery", message);
  return { message };
}
