import { useQuery } from "@tanstack/react-query";
import apiAllEmployeeReport from "../../../../services/manager/employeeDetails/apiAllEmployeeReport";

export default function AllEmployeeReportQuery() {
  const { data, isLoading } = useQuery({
    queryKey: ["allEmployeeReport"],
    queryFn: () => apiAllEmployeeReport(),
  });

  const records = data?.data;
  console.log("AllEmployeeReportQuery", records);

  const report = records?.map((record, idx) => ({
    id: idx,
    col1: record.employee_name,
    col2: record.manager_name,
    col3: record.team,
    col4: record.pending_leaves || 0,
    col5: record.approved_leaves || 0,
    col6: record.rejected_leaves || 0,
    col7: record.lwp || 0,
    col8: record.in_out || 0,
    col9: record.wfh || 0,
    col10: record.available_compoff || 0,
    col11: record.short_days || 0,
    col12: record.break_count || 0,
    col13: record.avg_hours || 0,
    col14: record.late_coming || 0,
    col15: record.off_days || 0,
  }));

  return { report, isLoading };
}
