import { useQuery } from "@tanstack/react-query";
import apiHolidayList from "../../services/holiday/apiHolidayList";

export default function HolidayListQuery() {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["holidayList"],
    queryFn: () => apiHolidayList(),
  });
  console.log("holiday data", data);

  const holidayList = data?.data.map((holiday, idx) => ({
    id: idx,
    holidayDate: holiday.holiday_date,
    subject: holiday.subject,
    holidayId: holiday.holiday_id,
  }));

  console.log("holidayList", holidayList);

  return { holidayList, isLoading, refetch };
}
